/* Map.css */

.map-section {
    padding: 60px 20px;
    text-align: center;
}

.map-section h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.map-container {
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #cccccc;
}
