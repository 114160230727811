/* Team.css */

.team {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.team h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.team-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team-card {
    width: 250px;
    margin: 20px;
    text-align: center;
}

.team-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.team-card h3 {
    font-size: 22px;
    margin: 15px 0 5px;
}

.team-card p {
    font-size: 16px;
    color: #666666;
}
