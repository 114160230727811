/* Секция услуг */
.services-section {
    padding: 20px;
    text-align: center;
}

/* Контейнер для всех карточек услуг */
.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    max-width: 1400px;
    margin: 0 auto;
}

/* Стиль для каждой отдельной карточки услуги */
.service-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    width: calc(16.66% - 20px);
    text-align: center;
    transition: transform 0.3s ease;

}

/* Эффект при наведении на карточку */
.service-card:hover {
    transform: translateY(-5px);
}

.service-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
}
