/* ContactForm.css */

.contact-form {
    padding: 60px 20px;
    text-align: center;
}

.contact-form h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.contact-form form {
    max-width: 600px;
    margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
}

.contact-form button {
    background-color: #ff6b6b;
    color: #ffffff;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #ff5252;
}
