.product-card {
    display: flex;             /* Используем flexbox для размещения заголовков в строку */
    flex-wrap: wrap;           /* Чтобы заголовки переносились на следующую строку при нехватке места */
    justify-content: space-between; /* Равномерное распределение заголовков */
    max-width: 1200px;         /* Ограничиваем ширину контейнера */
    margin: 0 auto;            /* Центрируем контейнер на странице */
    gap: 20px;                 /* Пространство между заголовками */
}

.product-card h2 {
    flex: 1 1 25%;             /* Ширина каждого заголовка - четверть строки */
    text-align: center;        /* Центрируем текст заголовка */
    margin: 10px;              /* Внешние отступы */
    padding: 10px;             /* Внутренние отступы для удобства чтения */
    background-color: #f8f8f8; /* Фон заголовка */
    border-radius: 8px;        /* Скругление углов */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Тень для заголовка */
}