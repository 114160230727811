/* Footer.css */

.footer {
    background-color: #f8f8f8;
    padding: 20px;
    text-align: center;
}

.footer p {
    margin: 0;
    color: #666666;
}
