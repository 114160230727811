/* HeroSection.css */

/* Секция с фоновым изображением */
.hero-section {
    background-image: url('../../../images/HeroSection/image333-1.jpg'); /* Ваше изображение */
    background-size: cover;       /* Изображение покрывает всю область */
    background-position: center;  /* Центрирование изображения */
    padding: 50px 20px;           /* Отступы вокруг текста */
    text-align: left;
    position: relative;
    width: 100%;                  /* Контейнер занимает всю ширину */
    height: 400px;                /* Высота секции, можно изменить по желанию */
    overflow: hidden;             /* Скрыть скролл-бары */
    display: flex;                /* Flex для выравнивания контента */
    justify-content: left;      /* Центровка контента по горизонтали */
    align-items: center;          /* Центровка контента по вертикали */
}

/* Контент с текстом поверх изображения */
.hero-content {
    position: relative;
    z-index: 2;                  /* Текст поверх изображения */
    color: white;                /* Белый цвет текста для контраста с изображением */
    background-color: rgba(0, 0, 0, 0.3); /* Полупрозрачный фон для читаемости текста */
    padding: 20px;               /* Отступы для текста */
    border-radius: 10px;         /* Скругленные углы */
    max-width: 600px;            /* Ограничение ширины для лучшего отображения */
    text-align: center;          /* Центрирование текста */
}

/* Текстовые стили */
.hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
    color: white;
}

.hero-content p {
    font-size: 24px;
    margin-bottom: 30px;
    color: white;
}

/* Кнопка CTA (Call to Action) */
.cta-button {
    background-color: #ff6b6b;
    color: #ffffff;
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    display: inline-block;
}

.cta-button:hover {
    background-color: #ff5252;
}
