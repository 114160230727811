/* AboutUs.css */

.about-us {
    padding: 60px 20px;
    text-align: center;
}

.about-us h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.about-us p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.about-us p strong {
    color: #ff6b6b;
}
