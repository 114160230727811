/* Testimonials.css */

.testimonials {
    padding: 60px 20px;
    text-align: center;
}

.testimonials h2 {
    font-size: 36px;
    margin-bottom: 40px;
}

.testimonials-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.testimonial-card {
    width: 300px;
    margin: 20px;
    text-align: center;
}

.testimonial-card img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.testimonial-card p {
    font-size: 16px;
    font-style: italic;
    margin: 15px 0;
}

.testimonial-card h4 {
    font-size: 18px;
    color: #333333;
}
