/* Navbar.css */

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.05); /* Полупрозрачный черный фон за текстом */
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: sticky;
}

.navbar .logo a {
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    color: #ff6b6b; /* Основной цвет */
}

.navbar .nav-links {
    display: flex;
}

.navbar .nav-links a {
    margin-left: 20px;
    text-decoration: none;
    color: #333333;
    font-size: 16px;
}

.navbar .nav-links a:hover {
    color: #ff6b6b;
}
