/* MainPage.css */

/* Общие стили для страницы */

.main-page {
    font-family: 'Arial', sans-serif;
    color: #333333;
}

section {
    margin-bottom: 60px;
}

h2 {
    color: #ff6b6b;
}

/* Адаптивность для мобильных устройств */

@media (max-width: 768px) {
    .services-list,
    .testimonials-list,
    .team-list {
        flex-direction: column;
        align-items: center;
    }

    .service-card,
    .testimonial-card,
    .team-card {
        width: 90%;
    }
}
body {
    overflow-x: hidden; /* Убираем горизонтальный скролл-бар */
    overflow-y: auto;   /* Оставляем возможность вертикального скроллинга */
    margin: 0;          /* Убираем отступы, которые могут вызвать горизонтальный скролл */
    padding: 0;
}
/* Стили для самого скролл-бара */
::-webkit-scrollbar {
    width: 12px;          /* Ширина вертикального скролл-бара */
    height: 12px;         /* Высота горизонтального скролл-бара (если есть) */
}

/* Стили для ползунка (scrollbar thumb) */
::-webkit-scrollbar-thumb {
    background-color: #ff6b6b;  /* Цвет ползунка (thumb) */
    border-radius: 10px;        /* Скругление углов ползунка */
    border: 2px solid #ffffff;  /* Отступ между ползунком и краями */
}

/* Стили для фона (scrollbar track) */
::-webkit-scrollbar-track {
    background-color: #f0f0f0;  /* Цвет дорожки (track) */
    border-radius: 10px;        /* Скругление углов */
}

